/** @jsxImportSource theme-ui */
import ButtonLink from "../components/ButtonLink";
import { routes } from "../utils/routes";

export default {
  title: <>Bonjour.</>,
  paragraph1: (
    <>
      I am a Sydney-based senior web developer who loves to craft beautiful digital products using
      web technology. I take special care for little details and interactions, following best
      practices, web standards and code hygiene.
    </>
  ),
  paragraph2: (
    <>
      Lucky enough to have been able to collaborate with Publicis, Qantas, Google, ustwo, and TBWA,
      I did some creative web development as a freelance for 2 years before joining
      <ButtonLink hasUnderline={false} href="https://leonardo.ai" target="_blank">
        Leonardo.ai
      </ButtonLink>
      in 2024.
    </>
  ),
  cta: <ButtonLink to={routes.projects}>Check out my work.</ButtonLink>,
};
